import React from "react"
import Helmet from "react-helmet"

import Navbar from "../components/Navbar"
import ImageBar from "../components/ImageBar"
import Footer from "../components/Footer"
import "typeface-playfair-display"
import "typeface-roboto"
import "./all.sass"

const TemplateWrapper = ({ children }) => (
  <>
    <Helmet title="Montgomery County MUD No. 42" />
    <Navbar />
    <ImageBar />
    {children}
    <Footer />
  </>
)

export default TemplateWrapper
